<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row style="margin-top: 16px">
                    <!--机构类型和机构列表组件-->
                    <ef-dept-type-and-dept-select
                        @selectDept="setDept"
                        @selectDeptType="setDeptType"
                        :need-default-dept-type="true"
                    />
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.detail.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.detail.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                id="table"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    width="180"
                    fixed="left"
                    v-if="showColumn('goodsName')"
                />
                <el-table-column
                    prop="deptName"
                    label="机构名称"
                    width="180"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column prop="startCount" label="期初库存数量" width="120" v-if="showColumn('startCount')" />
                <el-table-column prop="startCost" label="期初库存成本" width="150" v-if="showColumn('startCost')" />
                <el-table-column prop="endCount" label="期末库存数量" width="120" v-if="showColumn('endCount')" />
                <el-table-column prop="endCost" label="期末库存成本" width="150" v-if="showColumn('endCost')" />
                <el-table-column prop="grossProfit" label="毛利" width="150" v-if="showColumn('grossProfit')" />
                <template v-for="item in wmaBizType2Desc">
                    <el-table-column
                        :label="item.desc + '数量'"
                        :prop="item.type + '数量'"
                        :key="item.type + '数量'"
                        width="120"
                        v-if="showColumn(item.type + '数量')"
                    >
                        <template slot-scope="scope">
                            <span>{{ filterCostSum(scope.row.bizTypeCostSums, item.type).count }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="item.desc + '成本'"
                        :prop="item.type + '成本'"
                        :key="item.type + '成本'"
                        width="150"
                        v-if="showColumn(item.type + '成本')"
                    >
                        <template slot-scope="scope">
                            <span>{{ filterCostSum(scope.row.bizTypeCostSums, item.type).cost }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="item.desc + '金额'"
                        :prop="item.type + '金额'"
                        :key="item.type + '金额'"
                        width="150"
                        v-if="showColumn(item.type + '金额')"
                    >
                        <template slot-scope="scope">
                            <span>{{ filterCostSum(scope.row.bizTypeCostSums, item.type).occurMoney }}</span>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
            <ef-pagination :total="total" @pageChange="pageChange" :default-limit="form.limit" />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import Util from '@/js/Util';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'WmaGoodsCostSummary',
    components: { EfPagination, EfDeptTypeAndDeptSelect, EfStartDate, EfEndDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptType: null,
                deptCode: null,
                startTime: Util.nowFormatDate(),
                endTime: Util.nowFormatDate(),
                search: '',
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],

            wmaBizType2Desc: Util.allWmaBizType2Desc(),
        };
    },
    mounted() {
        Util.setIntervalAndTimeout(
            () => {
                return this.form.deptCode !== null;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    methods: {
        async handleQuery() {
            const rst = await this.$efApi.wmaStockCostChangeReportApi.detailSummaryList(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        filterCostSum(bizTypeCostSums, bizType) {
            return bizTypeCostSums.find(function (e) {
                return bizType === e.bizType;
            });
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(
                this,
                '移动加权进销存明细',
                '/report/wma/stockCostChange/exportDetailSummaryList',
                this.form
            );
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
};
</script>
